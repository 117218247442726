import { ReactNode } from 'react';
import { matchPath } from 'react-router-dom';
import { Permission, permissionGroupArray, userManagementPermissionArray } from '../../shared/enumeration/permission';
import BellSidebarIcon from '../shared/icons/BellSidebarIcon';
import EmailIcon from '../shared/icons/EmailIcon';
import HeadphonesIcon from '../shared/icons/HeadphonesIcon';
import InfoCircleSidebar from '../shared/icons/InfoCircleSidebar';
import LockIcon from '../shared/icons/LockIcon';
import MessageAlertSquareSidebar from '../shared/icons/MessageAlertSquareSidebar';
import CalendarIcon from '../shared/icons/sidebar/CalendarIcon';
import ClipboardIcon from '../shared/icons/sidebar/ClipboardIcon';
import FolderIcon from '../shared/icons/sidebar/FolderIcon';
import MessageSquareIcon from '../shared/icons/sidebar/MessageSquareIcon';
import PieChartIcon from '../shared/icons/sidebar/PieChartIcon';
import UserGroupIcon from '../shared/icons/sidebar/UserGroupIcon';
import UserCircleIcon from '../shared/icons/UserCircleIcon';
import UserGroup from '../shared/icons/UserGroup';
import { BiSolidStar, BiSolidCertification, BiHistory, BiListPlus, BiListMinus } from "react-icons/bi";

export const checkMatchPath = (pathArray: string[], pathName: string) => {
  let isActive = false;
  pathArray.forEach((path) => {
    const isMatch = matchPath({ path, end: false }, pathName);
    if (Boolean(isMatch)) {
      isActive = true;
      return;
    }
  });
  return isActive;
};
export interface SidebarItem {
  name: string;
  to: string;
  icon?: ReactNode;
  isTitle?: boolean;
  subItems?: SidebarItem[];
  permissions?: Permission[];
}

export const defaultSiderbarItems: SidebarItem[] = [
  {
    name: 'Quản lý phần quà',
    to: '/admin/users',
    icon: <CalendarIcon className="custom-nav-icon" />,
  },

  {
    name: 'Quản lý kết quả',
    to: '/admin/games',
    icon: <CalendarIcon className="custom-nav-icon" />,
  },
  // {
  //   name: 'Quản lý phần quà',
  //   to: '/admin/games',
  //   icon: <BiSolidCertification size={24} className="custom-nav-icon" />,
  // },
  // {
  //   name: 'Lịch sử đặt cược',
  //   to: '/admin/history',
  //   icon: <BiHistory size={24} className="custom-nav-icon" />,
  // },
  // {
  //   name: 'Quản lý rút tiền',
  //   to: '/admin/transaction',
  //   icon: <BiListMinus size={24} className="custom-nav-icon" />,
  // },
  // {
  //   name: 'Quản lý nạp tiền',
  //   to: '/admin/recharge',
  //   icon: <BiListPlus size={24} className="custom-nav-icon" />,
  // },
  // {
  //   name: 'Quản lý nạp tiền',
  //   to: '/admin/transaction',
  //   icon: <CalendarIcon className="custom-nav-icon" />,
  // },
  // {
  //   name: 'Tin nhắn',
  //   to: '/admin/message',
  //   icon: <MessageSquareIcon className="custom-nav-icon" />,
  // },
];

export const userSettingSidebarItems: SidebarItem[] = [
  {
    name: 'Thông tin hệ thống',
    to: '/admin/system-info',
    icon: <InfoCircleSidebar className="custom-nav-icon" />,
  },
  {
    name: 'Quyền',
    to: '/admin/permissions',
    icon: <UserGroup className="custom-nav-icon" />,
    permissions: permissionGroupArray,
  },
  {
    name: 'Tài khoản',
    to: '/admin/users',
    icon: <UserCircleIcon className="custom-nav-icon" />,
    permissions: userManagementPermissionArray,
  },
  {
    name: 'Cài đặt kế hoạch và thông báo',
    to: '/admin/plan-notification-setting',
    icon: <BellSidebarIcon className="custom-nav-icon" />,
  },
  {
    name: 'Hỗ trợ',
    to: '/admin/support',
    icon: <MessageAlertSquareSidebar className="custom-nav-icon" />,
  },
];

export const accountSettingSidebarItems: SidebarItem[] = [
  {
    name: 'Thông tin tài khoản',
    to: '/admin/account/profile',
    icon: <UserCircleIcon className="custom-nav-icon" />,
  },
  {
    name: 'Thay đổi mật khẩu',
    to: '/admin/account/change-password',
    icon: <LockIcon className="custom-nav-icon" />,
  },
  {
    name: 'Thay đổi email',
    to: '/admin/account/change-email',
    icon: <EmailIcon className="custom-nav-icon" />,
  },
  {
    name: 'Hỗ trợ',
    to: '/admin/account/support',
    icon: <HeadphonesIcon className="custom-nav-icon" />,
  },
];

export const handleSidebarItemsOnPathName = (path: string) => {
  if (
    checkMatchPath(
      userSettingSidebarItems.map((item) => item.to),
      path
    )
  ) {
    return userSettingSidebarItems;
  }

  if (
    checkMatchPath(
      accountSettingSidebarItems.map((item) => item.to),
      path
    )
  ) {
    return accountSettingSidebarItems;
  }
};

interface ITabItem {
  name: ReactNode;
  path: string;
}

export interface HeaderItem {
  name: ReactNode;
  to: string;
  tabItems?: ITabItem[];
  rightComponent?: ReactNode;
}

export const otherHeaderItem: HeaderItem[] = [
  {
    name: 'Cài đặt hệ thống',
    to: '/admin/settings',
    tabItems: [
      { name: 'Thông tin hệ thống', path: '/admin/settings/system-info' },
      { name: 'Quyền', path: '/admin/settings/permissions' },
      { name: 'Tài khoản', path: '/admin/settings/users' },
      { name: 'Cài đặt kế hoạch và thông báo', path: '/admin/settings/plan-notification-setting' },
      { name: 'Hỗ trợ', path: '/admin/settings/support' },
    ],
  },
  {
    name: 'Tài khoản của tôi',
    to: '/admin/account',
    tabItems: [
      { name: 'Thông tin', path: '/admin/account/profile' },
      { name: 'Bảo mật', path: '/admin/account/change-password' },
      // { name: 'Thông báo', path: '/admin/account/notifications' },
    ],
  },
];

export const handleGetHeaderItem = (path: string) => {
  const siderBarItem = [...defaultSiderbarItems];
  const convertSideBarItem = siderBarItem.map(({ name, to }) => ({ name, to }));
  const allHeaderItem = [...otherHeaderItem, ...convertSideBarItem];
  const headerItem = allHeaderItem.find((item) => checkMatchPath([item.to], path));
  if (headerItem) {
    return headerItem;
  }
  return null;
};
